import { ArrowBackIosNewOutlined } from '@mui/icons-material';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SwipeableDrawer,
  TextField,
  Typography
} from '@mui/material';
import { useLocation, useNavigate } from 'react-router-dom';
import { usePropertyServicesMutationMutation } from '../../generated/graphql';
import { useAuth } from '../../store';

import { imageFallback, useServiceAlert } from '../../utils';
import useInfo from '../../view/Dashboard/components/InfoCard';
import Stack from '@mui/material/Stack';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import useSpaForm from './spa-form-hook';
import dayjs from 'dayjs';
import { Layout } from '../../view/InRoomDining';

import { TalkToUS } from '../TalkToUs';
import {
  MENU_ITEM_FONT,
  DETAIL_TITLE_FONT,
  DETAIL_CONTENT_FONT
} from '../../constants';
import { split } from 'lodash';
import { MobileDateTimePicker } from '@mui/x-date-pickers';
type DrawerProps = {
  name: string;
  desc: string;
  image: string;
  open: boolean;
  serviceID?: number | null;
  handleOpen: () => void;
  handleClose: () => void;
  bookingUrl: string;
  bookingForm: boolean;
  spaIntegration?: boolean;
  notVerified?: boolean;
};
function Drawer(props: DrawerProps) {
  const {
    name,
    open,
    desc,
    image,
    serviceID,
    handleOpen,
    handleClose,
    bookingUrl,
    bookingForm,
    spaIntegration,
    notVerified
  } = props;
  const [requestService] = usePropertyServicesMutationMutation();
  const { button_bg_color } = useInfo();
  const guest = useAuth((state: any) => state.guest);
  const serviceAlert = useServiceAlert(() => {});
  const formatBookingUrl = (bookingUrl: string | undefined): string => {
    if (bookingUrl) {
      if (
        bookingUrl.startsWith('http://') ||
        bookingUrl.startsWith('https://')
      ) {
        return bookingUrl;
      }
      return `https://${bookingUrl}`;
    }
    return ''; // or handle undefined case appropriately
  };
  const navigate = useNavigate();
  const location = useLocation();
  const currentUrl = location.pathname;
  const roomId = split(currentUrl, '/')[1];
  const gotoSpaInterface = () => {
    navigate(`/${roomId}/spa`);
  };
  const clickHandler = async () => {
    if (!serviceID) {
      return;
    }
    try {
      const res = await requestService({
        variables: {
          input: {
            quantity: 1,
            property_id: guest?.property_id,
            room_id: guest?.room_id,
            task_id: serviceID
          }
        }
      });
      if (res.data?.requestService?.code === '200') {
        serviceAlert('Submitted Successfully');
        handleClose();
      } else {
        serviceAlert(
          res.data?.requestService?.message ||
            'Something went wrong! Please contact support'
        );
        handleClose();
      }

      // Success alert
    } catch (error) {
      serviceAlert(`Something went wrong! Please try again`);
      handleClose();
    }
  };
  const {
    onSave,
    register,
    handleSubmit,
    Treatments,
    bookingTime,
    setBookingTime,
    setSelectedTreatment,
    selectedTreatment,
    isDialogOpen,
    setIsDialogOpen
  } = useSpaForm();
  const { logoTileOpacity, showChatIcon } = useInfo();

  const imageUrl = image ? image : '/assets/no.jpg';
  const backgroundStyles = {
    background: `linear-gradient(rgba(0, 0, 0, ${
      0.8 - (logoTileOpacity || 0)
    }), rgba(100, 100, 100, ${
      0.8 - (logoTileOpacity || 0)
    })), url(${imageUrl})`,
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    borderRadius: '0px 0px 30px 30px'
  };

  const barStyles = {
    background: 'rgba(255, 224, 146, 1)',
    height: '56px',
    width: '100%',
    borderRadius: '10px'
  };
  const contentPanel = {
    background: 'rgba(255, 255, 255, 1)',
    height: '100%',
    width: '100%',
    borderRadius: '10px'
  };
  return (
    <SwipeableDrawer
      open={open}
      anchor="bottom"
      onOpen={handleOpen}
      onClose={handleClose}
    >
      <Layout sx={{}}>
        <Box
          onError={imageFallback}
          sx={{
            px: 2,
            py: 3,
            height: '259px',
            display: 'flex',
            justifyContent: 'space-between',
            '@media(max-width:280px)': {
              flexDirection: 'column'
            },
            boxShadow:
              '0px 0px 4px rgba(0, 0, 0, 0.04), 0px 4px 8px rgba(0, 0, 0, 0.06)',
            ...backgroundStyles,
            zIndex: 0
            // ...(sx ? sx : {})
          }}
        >
          <Typography
            variant="h6"
            sx={{
              ml: 1,
              cursor: 'pointer',
              ...MENU_ITEM_FONT,
              display: 'flex'
            }}
            onClick={handleClose}
          >
            <ArrowBackIosNewOutlined
              sx={{ mr: '4px', mt: '-2px' }}
            ></ArrowBackIosNewOutlined>
            {name}
          </Typography>
          {showChatIcon && <TalkToUS sx={{}}></TalkToUS>}
        </Box>
        <Box
          sx={{
            position: 'absolute',
            top: '245px',
            px: 2,
            overflow: 'hidden',
            zIndex: 1,
            width: '100%'
          }}
        >
          <Box
            sx={{
              ...barStyles,
              px: 2,
              display: 'flex',
              alignItems: 'center'
            }}
          >
            <Typography variant="h6" sx={{ ml: 1, ...DETAIL_TITLE_FONT }}>
              {name}
            </Typography>
          </Box>
        </Box>
        <Box
          sx={{
            position: 'absolute',
            top: '317px',
            px: 2,
            zIndex: 1,
            width: '100%',
            height: 'calc(100% - 330px)',
            boxShadow: '25px 50px 70px -20px rgba(0, 0, 0, 0.15)'
          }}
        >
          <Box
            sx={{
              ...contentPanel,
              ...DETAIL_CONTENT_FONT,
              overflow: 'auto',
              scrollbarWidth: 'none',
              p: 2
            }}
          >
            {desc?.toString().startsWith('<') ? (
              <div
                contentEditable="false"
                dangerouslySetInnerHTML={{ __html: desc?.toString() || '' }}
              ></div>
            ) : (
              <Typography sx={{ color: 'text.secondary' }}>{desc}</Typography>
            )}
            {serviceID && guest?.room_id && (
              <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                <Button
                  onClick={clickHandler}
                  sx={{
                    backgroundColor: button_bg_color,
                    color: 'white',
                    mt: 1
                  }}
                >
                  {name.trim() === 'Late Check-out'
                    ? 'Request for Late Check-out'
                    : 'Request'}
                </Button>
              </Box>
            )}
            {!notVerified && bookingUrl && (
              <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                <Button
                  target="_blank"
                  rel="noopener noreferrer"
                  href={formatBookingUrl(bookingUrl)}
                  sx={{
                    backgroundColor: button_bg_color,
                    color: 'white',
                    mt: 1
                  }}
                >
                  Book Now
                </Button>
              </Box>
            )}

            {!notVerified && bookingForm && (
              <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                <Button
                  onClick={() => {
                    setIsDialogOpen(true);
                    handleClose();
                  }}
                  sx={{
                    backgroundColor: button_bg_color,
                    color: 'white',
                    mt: 1
                  }}
                >
                  Book Now
                </Button>
              </Box>
            )}
            {!notVerified && spaIntegration && (
              <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                <Button
                  onClick={() => {
                    gotoSpaInterface();
                  }}
                  sx={{
                    backgroundColor: button_bg_color,
                    color: 'white',
                    mt: 1
                  }}
                >
                  Book Now
                </Button>
              </Box>
            )}
            <Dialog open={isDialogOpen} onClose={() => setIsDialogOpen(false)}>
              <DialogTitle id="alert-dialog-title">{`${name} Booking`}</DialogTitle>
              <Box component={'form'} onSubmit={handleSubmit(onSave)}>
                <DialogContent>
                  {/* <FormControl fullWidth>
                <br />
                <Autocomplete
                  value={selectedTreatment}
                  onChange={(event, newValue) => {
                    setSelectedTreatment(newValue?.id);
                  }}
                  id="controllable-states-demo"
                  options={Treatments || []}
                  getOptionLabel={(option) => option.name ? option.name : ""}
                  renderInput={params => (
                    <TextField {...params} label="Service" />
                  )}
                />
              </FormControl> */}
                  <FormControl fullWidth>
                    <InputLabel
                      id="demo-simple-select-label"
                      style={{ backgroundColor: 'white', padding: '0 5px' }}
                    >
                      Select Service
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      onChange={e => setSelectedTreatment(e.target.value)}
                      label="Section"
                      defaultValue={selectedTreatment}
                      MenuProps={{
                        PaperProps: {
                          style: {
                            maxHeight: 200
                          }
                        }
                      }}
                    >
                      {Treatments?.map((treatment, index) => (
                        <MenuItem key={index} value={treatment?.id}>
                          {treatment?.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                  <Stack></Stack>
                  <TextField
                    autoFocus
                    margin="dense"
                    id="email"
                    label="Email Address"
                    type="email"
                    fullWidth
                    variant="outlined"
                    {...register('guest_email')}
                  />
                  <TextField
                    autoFocus
                    margin="dense"
                    id="Phone"
                    label="Phone Number"
                    type="number"
                    fullWidth
                    variant="outlined"
                    {...register('guest_phone')}
                  />
                  <FormControl fullWidth>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DemoContainer components={['DateTimePicker']}>
                        <MobileDateTimePicker
                          label="Select DateTime"
                          value={bookingTime}
                          onChange={newValue => setBookingTime(newValue)}
                          minDate={dayjs()}
                          maxDate={dayjs(`${guest?.guest_departure}`)}
                        />
                      </DemoContainer>
                    </LocalizationProvider>
                  </FormControl>
                </DialogContent>
                <DialogActions>
                  <Button onClick={() => setIsDialogOpen(false)}>Cancel</Button>
                  <Button variant="contained" type="submit" autoFocus>
                    Send Booking
                  </Button>
                </DialogActions>
              </Box>
            </Dialog>
          </Box>
        </Box>
      </Layout>
    </SwipeableDrawer>
  );
}

export { Drawer };
